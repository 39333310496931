export default {
    grantUserState: {
        Novy: "Nový",
        OdesilaniNotifikace: "Odesílání notifikace",
        Odeslano: "Odesláno",
        Vyjadreno: "Vyjádřeno",
        Zruseno: "Zrušeno",
        Dokonceno: "Dokončeno",
        Notifikovano: "Notifikováno",
        Prijato: "Přijato",
        Probiha: "Probíhá",
        Zamitnuto: "Zamítnuto",
    },

    pozadavekStav: {
        Prijato: "Čeká na zpracování",
        ProbihaZpracovani: "Probíhá zpracování",
        Zpracovano: "Zpracováno",
        Chyba: "Chyba",
    },

    pozadavekIsDtmkStav: {
        CekaNaOdeslani: "Čeká na zpracování",
        PrijatoKrajem: "Probíhá zpracování",
        Zpracovano: "GAD zapracovatelná",
        ZpracovanoSChybami: "GAD nezapracovatelná",
        Chyba: "Chyba",
        ProbihaZpracovani: "Probíhá zpracování",
    },

    registry: {
        registrSubjektuEvidenceDti: "Registr VSP a editorů DTI",
        registrEditoruZps: "Registr editorů ZPS",
        registrUozi: "Registr AZI",
        registrOpravnenychZadatelu: "Registr žadatelů",
        registrIsDtmk: "Registr IS DTMK",
        registrBudoucichStavebniku: "Registr budoucích stavebníků",
    },

    statusZadatele: {
        VlastnikSpravceProvozovatel: "VSP",
        OpravnenyZadatel: "Oprávněný žadatel",
    },

    obsahDat: {
        zpsKresba: "Data základní prostorové situace - kresba",
        zpsPodrobneBody: "Data základní prostorové situace - podrobné body",
        zpsOdvozenePlosneTypyObjektu: "Data základní prostorové situace - odvozené plošné typy objektů",
        zpsDoprovodneInformace: "Data základní prostorové situace - doprovodné informace",
        zps: "Data základní prostorové situace",
        di: "Data dopravní infrastruktury",
        ti: "Data technické infrastruktury",
    },

    dostupnostDat: {
        verejna: "Veřejná data",
        neverejna: "Neveřejná data",
    },

    typDat: {
        stavova: "Stavová data",
        zmenova: "Změnová data",
    },

    vydejDatStav: {
        Prijato: "Čeká na zpracování",
        CekaNaOdeslani: "Čeká na zpracování",
        ProbihaZpracovani: "Probíhá zpracování",
        PrijatoKrajem: "Probíhá zpracování",
        Zpracovano: "Zpracováno",
        Chyba: "Chyba",
    },

    typPodaniGad: {
        KontrolniZaslani: "Kontrolní zaslání dat",
        GeodetickaAktualizacniDokumentace: "Požadavek na změnu",
        PozadavekNaZapracovaniGad: "Požadavek na zpracování GAD",
        AktualizacniDokumentace: "Požadavek na změnu",
    },

    vysledekPodaniGad: {
        GadZapracovatelna: "Zapracovatelná GAD",
        GadNezapracovatelna: "Nezapracovatelná GAD",
    },

    typPozadavku: {
        PozadavekNaVydejNeverejnychZmenovychDatovychSad: "Výdej neveřejných změnových datových sad",
        PozadavekNaVydejNeverejnychStavovychDatovychSad: "Výdej neveřejných stavových datových sad",
        PozadavekNaVyhotoveniNeverejneDatoveSady: "Vyhotovení neveřejné datové sady",
        PozadavekNaVyhotoveniVerejneDatoveSady: "Vyhotovení veřejné datové sady",
        PozadavekNaVydejVlastnichDatVsp: "Výdej vlastních dat VSP",
    },

    typZadosti: {
        "vydej-zmenovych-datovych-sad": "Žádost o výdej neveřejných změnových datových sad",
        "vydej-stavovych-datovych-sad": "Žádost o výdej neveřejných stavových datových sad",
        "vyhotoveni-neverejne-datove-sady": "Žádost o vyhotovení neveřejné datové sady",
        "vyhotoveni-verejne-datove-sady": "Žádost o vyhotovení veřejné datové sady",
        "vydej-vlastnich-dat": "Žádost o výdej vlastních dat VSP",
    },

    typHlaseniGad: {
        Informace: "Informace",
        Varovani: "Varování",
        Chyba: "Chyba",
    },

    typPozadavkuValidace: {
        PozadavekNaValidaciJvf: "Požadavek na validaci JVF",
    },

    validaceDatVysledek: {
        Validni: "Validní",
        Nevalidni: "Nevalidní",
    },

    validaceDatZaznam: {
        Informace: "Informace",
        Chyba: "Chyba",
        Varovani: "Varování",
    },

    stavIsDtmk: {
        Chyba: "Nedostupný",
        Odstavka: "Odstávka",
        Provoz: "Dostupný",
    },

    stavPozadavkuNaRegistraciSubjektu: {
        CekaNaVyjadreni: "Čeká na vyjádření",
        Novy: "Nový",
        Uzavreno: "Uzavřeno",
        Vyjadreno: "Vyjádřeno",
        Notifikovano: "Notifikováno",
        OdesilaniNotifikace: "Odesílání notifikace",
        Schvaleno: "Schváleno",
        Zamitnuto: "Zamítnuto",
        Zruseno: "Zrušeno",
    },

    psp: {
        aktualniPocetPriloh1: "U plánované stavby je již nahráno | U plánované stavby je již nahrána | U plánované stavby jsou již nahrány | U plánované stavby je již nahráno",
        aktualniPocetPriloh2: "příloh. | příloha. | přílohy. | příloh.",
    },
};
