<template>
    <Application
        title="Portál DMVS"
        :top-menu="topMenu"
        :menu="sideMenuToDisplay"
        :footer-links-left="footerLinks"
        :footer-links-right="footerIcons"
        @show-notifications="showNotifications"
    >
        <template #notice>
            <PrimeMessage v-if="!!notice" :closable="true" severity="warn" class="my-0">
                <span v-markdown.html="{ value: notice, customizer: customizeLinks }" />
            </PrimeMessage>
        </template>
    </Application>
</template>

<script>
import { useConfig } from "@agportal/config";
import { useInjectionContext } from "@agportal/injection";
import { useLogger } from "@agportal/logger";
import { defineComponent } from "@agportal/util/components";
import Application from "@dmvs/components/layout/dmvsapplication";
import { useSecurityContext } from "@dmvs/composables/security";
import struktura from "@dmvs/data/struktura-portalu";
import { customizeLinks } from "@dmvs/utils/common";
import { startsWith } from "lodash-es";
import { merge } from "lodash-es";
import PrimeMessage from "primevue/message";
import { computed, onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { clientConfigDefaults } from "./config/clientConfig";
import footerIcons from "./menu/footer-icons";
import footerLinks from "./menu/footer-links";
import topMenu from "./menu/top-menu";

export default defineComponent({
    components: {
        Application,
        PrimeMessage,
    },
    setup() {
        const { details } = useSecurityContext();
        const logger = useLogger();
        const route = useRoute();
        const router = useRouter();
        const config = useConfig();
        const injection = useInjectionContext();

        const { configService, weboveObsahyService } = injection.get("configService", "weboveObsahyService");

        const notice = ref(null);

        const isPsiAllowed = computed(() => config.clientConfig?.psp?.povoleno);

        const sideMenu = struktura.map((section) => {
            return {
                label: section.title,
                to: section.to,
                psi: section.psi,
            };
        });

        const sideMenuToDisplay = computed(() => {
            if (startsWith(route.path, "/portal")) {
                if (details.value && isPsiAllowed.value) {
                    return sideMenu.filter((item) => item.psi === true || item.psi === undefined);
                } else {
                    return sideMenu.filter((item) => !item.psi);
                }
            } else {
                return null;
            }
        });

        const showNotifications = () => {
            router.push({ name: "notifikace" });
        };

        const loadConfig = async () => {
            try {
                config.clientConfig = clientConfigDefaults;
                const data = await configService.vratKonfiguraci();
                config.clientConfig = merge({}, config.clientConfig, data);
            } catch (error) {
                logger.error("Došlo k chybě při načítání konfigurace aplikace.", error);
            }
        };

        const loadNotice = async () => {
            try {
                const data = await weboveObsahyService.ctiObsah("upozorneni");
                notice.value = data?.obsah;
            } catch (error) {
                logger.error("Došlo k chybě při načítání upozornění.", error);
            }
        };

        onBeforeMount(() => {
            loadConfig();
            loadNotice();
        });

        return {
            topMenu,
            sideMenuToDisplay,
            footerIcons,
            footerLinks,
            notice,
            customizeLinks,
            showNotifications,
        };
    },
});
</script>
