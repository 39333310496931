import { createRouter } from "@agportal/router";
import { createGroupGuard } from "@dmvs/composables/routing";
import { useSecurityContext } from "@dmvs/composables/security";
import { routes as common } from "@dmvs/router";
import { concat } from "lodash-es";

import { createSecurityGuard } from "@/security";

const router = createRouter({
    routes: concat(
        [],
        [
            {
                path: "/",
                name: "uvod",
                redirect: {
                    path: "/portal",
                },
            },
            {
                path: "/portal",
                name: "portal",
                component: () => import("@/views/Uvod"),
                meta: {
                    breadcrumbs: false,
                },
            },
            {
                path: "/portal/registrace",
                name: "registrace-old",
                component: () => import("@/views/Registrace"),
                meta: {
                    label: "Registrace",
                    breadcrumbs: true,
                    secured: true,
                },
                redirect: {
                    name: "registrace",
                },
            },
            {
                path: "/portal/povereni-uzivatele",
                name: "povereni-uzivatele",
                component: () => import("@/views/PovereniUzivatele"),
                meta: {
                    label: "Pověření uživatele",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-editora-dti",
                name: "povereni-editora-dti",
                component: () => import("@/views/PovereniEditoraDti"),
                meta: {
                    label: "Pověření editora",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-editora-zps",
                name: "povereni-editora-zps",
                component: () => import("@/views/PovereniEditoraZps"),
                meta: {
                    label: "Pověření editora ZPS",
                    secured: true,
                },
            },
            {
                path: "/portal/zmena-uzemi-editace-zps",
                name: "zmena-uzemi-editace-zps",
                component: () => import("@/views/ZmenaUzemiEditaceZps"),
                meta: {
                    label: "Změna území editace ZPS",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-zakladatele",
                name: "povereni-zakladatele",
                component: () => import("@/views/PrirazeniZakladatele"),
                meta: {
                    label: "Pověření zakladatele",
                    secured: true,
                },
            },
            {
                path: "/portal/povereni-vyjadrovatele",
                name: "povereni-vyjadrovatele",
                component: () => import("@/views/PovereniVyjadrovatele"),
                meta: {
                    label: "Pověření vyjadřovatele",
                    secured: true,
                },
            },
            {
                path: "/portal/pozadavek-na-registraci",
                name: "pozadavek-na-registraci",
                component: () => import("@/views/PozadavekNaRegistraci"),
                meta: {
                    label: "Požadavek na registraci",
                    secured: true,
                },
            },
            {
                path: "/portal/subjekty",
                name: "subjekty",
                component: () => import("@/views/RozcestnikSubjekty"),
                meta: {
                    label: "Subjekty DMVS",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "registrace",
                        name: "registrace",
                        component: () => import("@/views/Registrace"),
                        meta: {
                            label: "Registrace do IS DMVS",
                        },
                    },
                    {
                        path: "sprava-subjektu",
                        name: "sprava-subjektu",
                        component: () => import("@/views/SpravaSubjektu"),
                        redirect: {
                            name: "informace-o-subjektu",
                        },
                        meta: {
                            label: "Správa subjektu",
                            group: "sprava-subjektu",
                        },
                        children: [
                            {
                                path: "informace",
                                name: "informace-o-subjektu",
                                component: () => import("@/components/composition/InformaceSubjektu"),
                                meta: {
                                    label: "Informace",
                                },
                            },
                            {
                                path: "notifikace-subjektu",
                                name: "notifikace-subjektu",
                                component: () => import("@/components/composition/NastaveniNotifikaciSubjektu"),
                                meta: {
                                    label: "Notifikace",
                                },
                            },
                            {
                                path: "registry",
                                component: () => import("@/components/composition/RegistrySubjektu"),
                                meta: {
                                    label: "Registry",
                                },
                            },
                            {
                                path: "dtm",
                                component: () => import("@/components/composition/InformaceIsDtm"),
                                meta: {
                                    label: "DTM",
                                },
                            },
                            {
                                path: "zps",
                                component: () => import("@/components/composition/InformaceEditoraZps"),
                                meta: {
                                    label: "Editor ZPS",
                                },
                            },
                            {
                                path: "zakladatele",
                                component: () => import("@/components/composition/ZakladateleSubjektu"),
                                meta: {
                                    label: "Zakladatelé",
                                },
                            },
                            {
                                path: "povereni",
                                component: () => import("@/components/composition/UzivateleSubjektu"),
                                meta: {
                                    label: "Pověření",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/dti",
                name: "dti",
                component: () => import("@/views/RozcestnikDti"),
                meta: {
                    label: "Dopravní a technická infrastruktura",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "casti-dti",
                        name: "casti-dti",
                        component: () => import("@/views/CastiDti"),
                        meta: {
                            label: "Části DTI",
                            group: "casti-dti",
                            breadcrumbs: true,
                            secured: true,
                        },
                        children: [
                            {
                                path: ":castDtiId",
                                name: "detail-casti-dti",
                                meta: {
                                    label: "#{castDti}",
                                },
                                component: () => import("@/views/DetailCastiDti"),
                                redirect: {
                                    name: "informace-casti-dti",
                                },
                                children: [
                                    {
                                        path: "informace",
                                        name: "informace-casti-dti",
                                        component: () => import("@/components/composition/InformaceCastiDti"),
                                        meta: {
                                            label: "Informace",
                                        },
                                    },
                                    {
                                        path: "vyjadrovatel",
                                        component: () => import("@/components/composition/Vyjadrovatel"),
                                        meta: {
                                            label: "Vyjadřovatel",
                                        },
                                    },
                                    {
                                        path: "kontaktni-udaje",
                                        component: () => import("@/components/composition/KontaktniUdajeCastiDti"),
                                        meta: {
                                            label: "Kontaktní údaje",
                                            tooltip: "Kontaktní údaje pro podání žádosti o stanovisko",
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "rozsahy-editace-dti",
                        name: "rozsahy-editace-dti",
                        component: () => import("@/views/RozsahyEditaceDti"),
                        meta: {
                            label: "Rozsahy editace DTI",
                            group: "rozsahy-editace-dti",
                            breadcrumbs: true,
                            secured: true,
                        },
                        children: [
                            {
                                path: ":rozsahDtiId",
                                name: "detail-rozsahu-dti",
                                meta: {
                                    label: "#{rozsahDti}",
                                },
                                component: () => import("@/views/DetailRozsahuEditaceDti"),
                                redirect: {
                                    name: "informace-rozsahu-dti",
                                },
                                children: [
                                    {
                                        path: "informace",
                                        name: "informace-rozsahu-dti",
                                        component: () => import("@/components/composition/InformaceRozsahuDti"),
                                        meta: {
                                            label: "Informace",
                                        },
                                    },
                                    {
                                        path: "editori",
                                        component: () => import("@/components/composition/Editori"),
                                        meta: {
                                            label: "Editoři",
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "vydej-vsp",
                        name: "vydej-vsp",
                        component: () => import("@/views/RozcestnikVsp"),
                        meta: {
                            label: "Výdej vlastních dat VSP",
                        },
                        children: [
                            {
                                path: "vydej",
                                name: "vydej-vsp-zadost",
                                component: () => import("@/views/VydejDatVsp"),
                                meta: {
                                    label: "Žádost o vlastní data VSP",
                                    secured: true,
                                },
                            },
                            {
                                path: "zadosti",
                                name: "vydej-vsp-prehled",
                                component: () => import("@/views/PrehledZadostiVydejeVlastnichDat"),
                                meta: {
                                    label: "Přehled žádostí o výdej vlastních dat VSP",
                                    group: "zadosti-o-vydej-vlastnich-dat",
                                    secured: true,
                                },
                                children: [
                                    {
                                        path: ":zadostId",
                                        name: "vydej-vsp-detail",
                                        meta: {
                                            label: "#{zadostId}",
                                        },
                                        component: () => import("@/views/DetailZadostiVydejeVlastnichDat"),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "uzemi-spravcu-dti",
                        name: "uzemi-spravcu-dti",
                        component: () => import("@/views/ZjisteniUzemiSpravcuDti"),
                        meta: {
                            label: "Zjištění území správců dopravní a technické infrastruktury",
                        },
                    },
                ],
            },
            // GAD routes for users registered in AZI register
            {
                path: "/portal/gad",
                name: "gad",
                component: () => import("@/views/RozcestnikGad"),
                meta: {
                    label: "Geodetická aktualizační dokumentace",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "stav-zpracovani",
                        name: "gad-stav-zpracovani",
                        component: () => import("@/views/StavPodaniGad"),
                        meta: {
                            label: "Zjištění stavu zpracování GAD",
                        },
                    },
                    {
                        path: "kontrola-dat",
                        name: "gad-kontrola-dat",
                        component: () => import("@/views/KontrolniZaslaniGad"),
                        meta: {
                            label: "Kontrolní zaslání dat",
                        },
                    },
                    {
                        path: "aktualizace-dat",
                        name: "gad-aktualizace-dat",
                        component: () => import("@/views/ZaslaniZmenyGad"),
                        meta: {
                            label: "Požadavek na změnu",
                        },
                    },
                    {
                        path: "podani",
                        name: "podani-gad",
                        component: () => import("@/views/PodaniGad"),
                        meta: {
                            label: "Přehled podání",
                            group: "podani-gad",
                        },
                        children: [
                            {
                                path: ":idPodani",
                                name: "detail-podani-gad",
                                meta: {
                                    label: "#{idPodani}",
                                },
                                component: () => import("@/views/DetailPodaniGad"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/stav-zpracovani-gad",
                name: "stav-zpracovani-gad-old",
                component: () => import("@/views/StavPodaniGad"),
                meta: {
                    label: "Stav zpracování",
                    secured: true,
                },
                redirect: {
                    name: "gad-stav-zpracovani",
                }
            },
            {
                path: "/portal/psp",
                name: "psp",
                component: () => import("@/views/RozcestnikPsp"),
                meta: {
                    label: "Plánované stavební práce",
                    breadcrumbs: true,
                    secured: true,
                },
                beforeEnter: (to, from, next) => {
                    const { details } = useSecurityContext();

                    if (!details.value.isPsiAllowed) {
                        next({ name: "uvod" });
                    } else {
                        next();
                    }
                },
                children: [
                    {
                        path: "evidence-psp",
                        name: "evidence-psp",
                        component: () => import("@/views/EvidencePsp"),
                        meta: {
                            label: "Evidence PSP",
                            group: "evidence-psp",
                        },
                        children: [
                            {
                                path: ":stavbaId",
                                name: "detail-psp",
                                meta: {
                                    label: "#{stavba}",
                                },
                                component: () => import("@/views/DetailPsp"),
                                redirect: {
                                    name: "informace-psp",
                                },
                                children: [
                                    {
                                        path: "informace",
                                        name: "informace-psp",
                                        component: () => import("@/components/composition/InformacePsp"),
                                        meta: {
                                            label: "Informace",
                                        },
                                    },
                                    {
                                        path: "harmonogram-stavby",
                                        component: () => import("@/components/composition/HarmonogramPsp"),
                                        meta: {
                                            label: "Harmonogram stavby",
                                        },
                                    },
                                    {
                                        path: "kontaktni-udaje",
                                        component: () => import("@/components/composition/KontaktniUdajePsp"),
                                        meta: {
                                            label: "Kontaktní údaje",
                                        },
                                    },
                                    {
                                        path: "prilohy",
                                        component: () => import("@/components/composition/PrilohyPsp"),
                                        meta: {
                                            label: "Přílohy",
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/vydej-dat",
                name: "vydej-dat",
                component: () => import("@/views/RozcestnikVydejDat"),
                meta: {
                    label: "Výdej dat DTM",
                    group: "vydej-dat",
                    breadcrumbs: true,
                },
                children: [
                    {
                        path: "datove-sady-zps",
                        name: "datove-sady-zps",
                        component: () => import("@/views/OpenData"),
                        meta: {
                            label: "Datové sady ZPS",
                        },
                    },
                    {
                        path: "predpripravene-datove-sady",
                        name: "predpripravene-datove-sady",
                        component: () => import("@/views/VydejPreddefinovanychDatovychSad"),
                        meta: {
                            label: "Výdej předpřipravených datových sad",
                            secured: true,
                        },
                    },
                    {
                        path: "data-v-definovanem-uzemi",
                        name: "data-v-definovanem-uzemi",
                        component: () => import("@/views/VyhotoveniDatovychSad"),
                        meta: {
                            label: "Výdej dat v definovaném území",
                            secured: true,
                        },
                    },
                    {
                        path: "zadosti",
                        name: "zadosti",
                        component: () => import("@/views/PrehledZadostiVydejeDat"),
                        meta: {
                            label: "Přehled žádostí o výdej dat",
                            group: "zadosti-o-vydej-dat",
                            secured: true,
                        },
                        children: [
                            {
                                path: ":typZadosti/:zadostId",
                                name: "detail-zadosti",
                                meta: {
                                    label: "#{zadostId}",
                                },
                                component: () => import("@/views/DetailZadostiVydejeDat"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/uzemi-spravcu-dti",
                name: "uzemi-spravcu-dti-old",
                component: () => import("@/views/ZjisteniUzemiSpravcuDti"),
                meta: {
                    label: "Zjištění území správců dopravní a technické infrastruktury",
                    secured: true,
                },
                redirect: {
                    name: "uzemi-spravcu-dti",
                },
            },
            {
                path: "/portal/jvf",
                name: "jvf",
                component: () => import("@/views/RozcestnikJvf"),
                meta: {
                    label: "JVF DTM",
                    group: "jvf",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "verze-jvf",
                        name: "verze-jvf",
                        component: () => import("@/views/Jvf"),
                        meta: {
                            label: "Verze JVF",
                        },
                    },
                    {
                        path: "validace-jvf",
                        name: "validace-jvf",
                        component: () => import("@/views/ValidaceJvf"),
                        meta: {
                            label: "Validace JVF souboru",
                        },
                    },
                    {
                        path: "pozadavky-validace-jvf",
                        name: "pozadavky-validace-jvf",
                        component: () => import("@/views/PrehledPozadavkuNaValidaciJvf"),
                        meta: {
                            label: "Přehled požadavků na validaci JVF",
                            group: "pozadavky-validace-jvf",
                        },
                        children: [
                            {
                                path: ":subjektId/:pozadavekId",
                                name: "detail-pozadavku-validace",
                                meta: {
                                    label: "#{typPozadavku}",
                                },
                                component: () => import("@/views/DetailPozadavkuValidaceDat"),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/portal/prehledy-ciselniky",
                name: "prehledy-ciselniky",
                component: () => import("@/views/RozcestnikCiselniky"),
                meta: {
                    label: "Přehledy a číselníky",
                    breadcrumbs: true,
                    secured: true,
                },
                children: [
                    {
                        path: "ciselniky",
                        name: "ciselniky",
                        component: () => import("@/views/Ciselniky"),
                        meta: {
                            label: "Seznam číselníků",
                        },
                    },
                    {
                        path: "certifikaty",
                        name: "certifikaty",
                        component: () => import("@/views/Certifikaty"),
                        meta: {
                            label: "Certifikáty",
                        },
                    },
                    {
                        path: "azi",
                        name: "azi",
                        component: () => import("@/views/Azi"),
                        meta: {
                            label: "Autorizovaní zeměměřičtí inženýři",
                        },
                    },
                ],
            },
            {
                path: "/portal/systemove-informace",
                name: "systemove-informace",
                component: () => import("@/views/RozcestnikSystemoveInformace"),
                meta: {
                    label: "Systémové informace",
                    breadcrumbs: true,
                },
                children: [
                    {
                        path: "informace-o-systemu",
                        name: "informace-o-systemu",
                        component: () => import("@/views/InformaceOSystemu"),
                        meta: {
                            label: "Informace o systému",
                            group: "informace-o-systemu",
                        },
                        children: [
                            {
                                path: ":uuid",
                                name: "detail-informace-o-systemu",
                                component: () => import("@/views/DetailInformaceOSystemu"),
                                meta: {
                                    label: "#{titulek}",
                                },
                            },
                        ],
                    },
                    {
                        path: "stav-is-dtm-kraju",
                        name: "stav-is-dtm-kraju",
                        component: () => import("@/views/StavIsDtmKraju"),
                        meta: {
                            label: "Stav IS DTM krajů",
                        },
                    },
                ],
            },
            {
                path: "/portal/notifikace",
                name: "notifikace",
                component: () => import("@/views/Notifikace"),
                meta: {
                    label: "Notifikace",
                    breadcrumbs: true,
                    group: "notifikace",
                },
            },
            {
                path: "/mapovy-portal",
                name: "mapovy-portal",
                component: () => import("@/views/RozcestnikMapovyPortal"),
                meta: {
                    label: "Mapový portál",
                    breadcrumbs: false,
                },
                children: [
                    {
                        path: "mapa",
                        name: "detail-mapovy-portal",
                        meta: {
                            label: "Mapa",
                            fullwidth: true,
                        },
                        component: () => import("@/views/MapovyPortal"),
                    },
                ],
            },
            {
                path: "/informace-o-dmvs",
                name: "informace-o-dmvs",
                component: () => import("@/views/InformaceODmvs"),
                meta: {
                    label: "Informace o DMVS",
                    breadcrumbs: false,
                },
            },
            {
                path: "/prohlaseni-o-pristupnosti",
                name: "prohlaseni-o-pristupnosti",
                component: () => import("@/views/ProhlaseniOPristupnosti"),
                meta: {
                    label: "Prohlášení o přístupnosti",
                    breadcrumbs: true,
                },
            },
            {
                path: "/rss",
                name: "rss",
                component: () => import("@/views/SeznamRssKanalu"),
                meta: {
                    label: "RSS kanály",
                    breadcrumbs: true,
                },
            },
        ],
        common
    ),
});

router.beforeEach(createSecurityGuard());
router.beforeEach(createGroupGuard());

export default router;
