import { useLogger } from "@agportal/logger";
import { createRestClient } from "@dmvs/client";
import { strict as assert } from "assert";

export default class CastoPouzivaneService {
    constructor(url) {
        assert(url, "url has to be specified");

        this.logger = useLogger();
        this.rest = createRestClient({
            baseURL: url,
        });
    }

    async nactiCastoPouzivane() {
        const { data } = await this.rest.get("");

        this.logger.debug(
            "CastoPouzivaneService.nactiCastoPouzivane returns {0}",
            data
        );

        return data;

    }
}
