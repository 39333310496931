import { useConfig } from "@agportal/config";
import { createInjectionContext } from "@agportal/injection";
import AdresyService from "@dmvs/services/adresyservice";
import CertifikatyService from "@dmvs/services/certifikatyservice";
import CiselnikyDmvsService from "@dmvs/services/ciselnikydmvsservice";
import CiselnikyService from "@dmvs/services/ciselnikyservice";
import ConfigService from "@dmvs/services/configservice";
import DocasneSouboryService from "@dmvs/services/docasnesouboryservice";
import JvfService from "@dmvs/services/jvfservice";
import MapovyProhlizecService from "@dmvs/services/mapovyprohlizecservice";
import NotificationService from "@dmvs/services/notificationservice";
import ProvozniInformaceService from "@dmvs/services/provozniinformaceservice";
import PspSubjektuService from "@dmvs/services/pspsubjektuservice";
import SubjektyService from "@dmvs/services/subjektyservice";
import WfsService from "@dmvs/services/wfsservice";
import WmsLayerService from "@dmvs/services/wmslayerservice";
import WmsService from "@dmvs/services/wmsservice";
import WmtsLayerService from "@dmvs/services/wmtslayerservice";

import AziService from "@/services/AziService";
import CastoPouzivaneService from "@/services/CastoPouzivaneService";
import GadService from "@/services/GadService";
import IsDtmKrajuService from "@/services/IsDtmKrajuService";
import IsDtmkService from "@/services/IsDtmkService";
import OpenDataService from "@/services/OpenDataService";
import RegistraceSubjektuService from "@/services/RegistraceSubjektuService";
import UzivateleSubjektuService from "@/services/UzivateleSubjektuService";
import ValidaceVstupnichSouboruService from "@/services/ValidaceVstupnichSouboruService";
import VydejDatovychSadService from "@/services/VydejDatovychSadService";
import WeboveObsahyService from "@/services/WeboveObsahyService";
import ZakladateleSubjektuService from "@/services/ZakladateleSubjektuService";
import ZjisteniUzemiSpravcuService from "@/services/ZjisteniUzemiSpravcuService";

const config = useConfig();

export default createInjectionContext({
    objects: {
        configService: new ConfigService(config.endpoints.clientConfig),
        adresyService: new AdresyService(config.endpoints.ruian),
        subjektyService: new SubjektyService(config.endpoints.subjekty),
        registraceSubjektuService: new RegistraceSubjektuService(config.endpoints.registraceSubjektu),
        uzivateleSubjektuService: new UzivateleSubjektuService(config.endpoints.subjekty),
        gadService: new GadService(config.endpoints.gad),
        zakladateleSubjektuService: new ZakladateleSubjektuService(config.endpoints.subjekty),
        ciselnikyService: new CiselnikyService(config.endpoints.ciselniky),
        isDtmkService: new IsDtmkService(config.endpoints.isDtmk),
        docasneSouboryService: new DocasneSouboryService(config.endpoints.docasneSoubory),
        provozniInformaceService: new ProvozniInformaceService(config.endpoints.provozniInformace),
        jvfService: new JvfService(config.endpoints.jvf),
        ciselnikyDmvsService: new CiselnikyDmvsService(config.endpoints.ciselnikyDmvs),
        vydejDatovychSadService: new VydejDatovychSadService(config.endpoints.vydejDat),
        certifikatyService: new CertifikatyService(config.endpoints.certifikaty),
        wmsService: new WmsService(config.endpoints.wms),
        openDataService: new OpenDataService(config.endpoints.openData),
        aziService: new AziService(config.endpoints.azi),
        validaceVstupnichSouboruService: new ValidaceVstupnichSouboruService(config.endpoints.validaceVstupnichSouboru),
        mapovyProhlizecService: new MapovyProhlizecService(config.endpoints.mapovyProhlizec),
        wfsService: new WfsService(config.endpoints.wfs),
        weboveObsahyService: new WeboveObsahyService(config.endpoints.weboveObsahy),
        isDtmKrajuService: new IsDtmKrajuService(config.endpoints.isDtmKraju),
        zjisteniUzemiSpravcuService: new ZjisteniUzemiSpravcuService(config.endpoints.zjisteniUzemiSpravcu),
        wmtsLayerService: new WmtsLayerService(config.endpoints.wmtsLayer),
        wmsLayerService: new WmsLayerService(config.endpoints.wmsLayer),
        notificationService: new NotificationService(config.endpoints.notification),
        pspSubjektuService: new PspSubjektuService(config.endpoints.psp),
        castoPouzivaneService: new CastoPouzivaneService(config.endpoints.castoPouzivane),
    },
});
